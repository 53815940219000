import {useStore} from 'vuex'

const useAuth = () => {

    const store = useStore()

    const createUser = async(user) => {
        console.log(user);
    }

    const loggin = async(credenciales) => {
        const respuesta = await store.dispatch('auth/iniciarSesion', credenciales);
        return respuesta
    }

    return {
        createUser,
        loggin
    }
}

export default useAuth